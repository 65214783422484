<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="6"
        cols="12"
      >
        <b-card
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Statistics</b-card-title>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                key="DollarSignIcon"
                md="4"
                sm="6"
                class="mb-2 mb-md-0"
              >
                <b-col
                  key="ActivityIcon"
                  md="12"
                  sm="12"
                  class="mb-2 mb-md-0"
                >
                  <b-media no-body>
                    <b-media-aside
                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="info"
                      >
                        <feather-icon
                          size="24"
                          icon="ActivityIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        Pesanan Bulan Ini
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ order }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-col>
              <b-col
                key="DollarSignIcon"
                md="4"
                sm="6"
                class="mb-2 mb-md-0"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="warning"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Pemasukan
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Rp. {{ profit }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col
                key="DollarSignIcon"
                md="4"
                sm="6"
                class="mb-2 mb-md-0"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="warning"
                    >
                      <feather-icon
                        size="24"
                        icon="DollarSignIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      Keuntungan Bersih
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Rp. {{ cleanProfit }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="6"
        cols="12"
      >
        <b-card body-class="pb-50 scroll overflow-auto">
          <b-alert
            v-for="(data, index) in alert"
            :key="index"
            variant="primary"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="BellIcon"
              />
              <span>{{ data.keterangan }}</span>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <calendar />
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BAlert, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
// import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import Calendar from '@/views/Calendar/Calendar.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BAlert,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    // StatisticCardVertical,
    Calendar,
  },
  data() {
    return {
      order: 0,
      profit: 0,
      cleanProfit: 0,
      alert: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/dashboard').then(response => {
        if (response.data.status === true) {
          this.order = response.data.data.booking_bulan_ini
          this.profit = response.data.data.booking_keuntungan
          this.cleanProfit = response.data.data.booking_keuntungan_bersih
          this.alert = response.data.data.pajak
        }
      })
    },
  },
}
</script>

<style>
.scroll {
    max-height: 200px;
}
</style>
